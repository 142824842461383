// store.js
import { configureStore } from '@reduxjs/toolkit';

// Define your initial state
const initialState = {
    name: '',
    email: '',
    templateChosen: '',
    paymentStatus: null,
    paymentCustomerEmail: '',
    paymentAmount: '',
    paymentId: '',
    breezeQrUrl: '',
    fromPromoCodeRedemption: false
};

// Define your reducer
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                ...action.payload,
            };
        case 'RESET_STATE':
            return initialState;
        default:
            return state;
    }
};

// Create the Redux store
const store = configureStore({
    reducer: rootReducer,
});

// Export action creators if needed
export const setData = (payload) => ({
    type: 'SET_DATA',
    payload,
});

export const resetState = () => ({
    type: 'RESET_STATE',
});

export default store;
