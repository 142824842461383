import React, {useEffect} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {BACKEND_URL, PROCEED_PAYMENT_FORM} from "../config/constants";
import axios from "axios";
import {setData} from "../store";
import ButtonComponent from "./Buttons/ButtonComponent";
import {Typography} from "@mui/material";

const ReturnPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mySliceState = useSelector((state) => state);
    console.log('Current State:', mySliceState);
    const status = useSelector((state) => state.paymentStatus);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const sessionId = urlParams.get("session_id");

                const response = await axios.get(
                    BACKEND_URL + `/stripe/api/session-status`,
                    {
                        params: {session_id: sessionId},
                    }
                );

                const data = response.data;
                console.log("Received response data:", data);

                dispatch(
                    setData({
                        paymentStatus: data.status,
                        paymentCustomerEmail: data.customer_email,
                        paymentId: data.payment_id,
                        paymentAmount: data.payment_amount,
                    })
                );
                console.log("here")
                // Conditionally navigate based on the fetched status
                if (data.status === "" || data.status === null) {
                    console.log("status is null or empty. Directing to / ")
                    navigate("/");
                }
            } catch (error) {
                console.error("Error fetching session status:", error);
                navigate("/");
            }
        };
        fetchData();
    }, []);

    if (status === "open") {
        return <Navigate to="/checkout"/>;
    }

    const successSection = (
        <section id="success">
            <Typography fontSize={48} variant="h1" fontWeight={500} my={3}>
                THANK YOU
            </Typography>
            <Typography gutterBottom variant="body1">
                We've officially received your payment<br/>Please check your email inbox for the receipt
            </Typography>
            <Typography gutterBottom variant="body1" mt={5}>
                Moving forward, we will need some details from you<br/>before we can generate your photo booth QR ticket.
            </Typography>
            <Typography gutterBottom variant="body1" my={5}>
                Ready?! Click the button below
            </Typography>

            <Link to={"/form"}>
                <ButtonComponent
                    backgroundColor={PROCEED_PAYMENT_FORM.backgroundColor}
                    borderColor={PROCEED_PAYMENT_FORM.borderColor}
                    buttonText={PROCEED_PAYMENT_FORM.buttonText}
                    color={PROCEED_PAYMENT_FORM.color}
                    width={PROCEED_PAYMENT_FORM.width}
                    onClick={() => {
                    }}
                />
            </Link>
        </section>
    );

    return (
        <>
            {status === "complete" && successSection}
        </>
    );
};

export default ReturnPage;
