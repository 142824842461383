import {Button} from "@mui/material";
import React from "react";
import {BACK_BUTTON} from "../../config/constants";

const BackButton = () => {
    return (
        <Button
            type="button"
            variant="outlined"
            size="large"
            style={{
                backgroundColor: BACK_BUTTON.backgroundColor,
                color: BACK_BUTTON.color,
                borderColor: BACK_BUTTON.borderColor,
                width: BACK_BUTTON.width,
                /*fontSize: BACK_BUTTON.fontSize,*/
                marginTop: BACK_BUTTON.marginTop,
                marginBottom: BACK_BUTTON.marginBottom,
            }}
        >
            {BACK_BUTTON.buttonText}
        </Button>
    );
};

export default BackButton;
