import React from "react";
import {LOGO_URL} from "../config/constants";

const Navbar = ({isLarge}) => {

    
    const handleLogoClick = () => {
        window.open("https://instantly.sg", "_blank");
    };
    

    return (
        <header style={{ 
            height: isLarge ? "8em" : "6em", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center" 
        }}>
            <a href="https://instantly.sg" target="_blank" rel="noopener noreferrer">
                <img 
                    src={LOGO_URL}
                    alt="Instantly.sg logo"
                    style={{ 
                        height: isLarge ? "4em" : "3em",  // Adjust the size of the logo
                        maxWidth: "100%",
                        objectFit: "contain"
                    }}
                />
            </a>
        </header>
        /*
        <header style={{height: isLarge ? "8em" : "6em"}}>
            <a href="https://instantly.sg" target="_blank" rel="noopener noreferrer">
                <img
                    src={LOGO_URL}
                    alt="Logo"
                    style={{height: "100%", width: "auto"}}
                    onClick={handleLogoClick}
                />
            </a>
        </header>
        */
    );
};

export default Navbar;
