import React from "react";
import {OPTIONS, TEST_OPTIONS} from "../config/constants";
import ProductButton from "./ProductButton";
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BackButton from "./Buttons/BackButton";
import {useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";

const PaymentMenu = () => {
    const navigate = useNavigate();
    const mySliceState = useSelector((state) => state);
    console.log('Current State:', mySliceState);
    
    const handleClick = (url) => {
        navigate(url);
    };

    return (
        <>
            <Typography fontSize={30} variant="h1" fontWeight={500} my={3}>
                SELECT AN OPTION BELOW
            </Typography>
            <Grid container spacing={2} rowGap={2}>
                {OPTIONS.map(
                    ({amount, description, image_url, price_id, title, url}) => (
                        <Grid
                            key={price_id}
                            xs={12}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <ProductButton
                                headerText={title}
                                description={description}
                                amount={amount}
                                url={url}
                                imageURL={image_url}
                            />
                        </Grid>
                    )
                )}
            </Grid>
            <Box mt={5}>
                <Link to={"/"}>
                    <BackButton/>
                </Link>
            </Box>
        </>
    );
};

export default PaymentMenu;