import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import ButtonComponent from "./Buttons/ButtonComponent";
import {BACK_HOME_BUTTON} from "../config/constants";

const ThankYouPageTest = () => {
    const breezeQr = useSelector((state) => state.breezeQrUrl);

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography gutterBottom variant="body1" mb={4}>
                Below is your QR ticket to activate our photo booth.
            </Typography>
            <img
                src={breezeQr}
                alt="QR Ticket"
                width={250}
                height={250}
                style={{objectFit: "contain", marginBottom: "1em"}}
            />

            <Link to={"/"}>
                <ButtonComponent
                    backgroundColor={BACK_HOME_BUTTON.backgroundColor}
                    borderColor={BACK_HOME_BUTTON.borderColor}
                    buttonText={BACK_HOME_BUTTON.buttonText}
                    color={BACK_HOME_BUTTON.color}
                    width={BACK_HOME_BUTTON.width}
                    bottomSpace={100}
                    onClick={() => {
                    }}
                />
            </Link>
        </Box>
    );
};

export default ThankYouPageTest;
