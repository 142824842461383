
export const STRIPE_API_KEY = "pk_live_Nqyuh6UIGlW3w8yV0hHKRMPn";
export const BACKEND_URL = "https://walkinpb-backend2024-d4baa478fb54.herokuapp.com";
//export const BACKEND_URL = "http://localhost:23188";

/*
export const STRIPE_API_KEY = "pk_test_uLU1zJFfZA5E8Vepgwt45S1j";
export const BACKEND_URL = "https://1f57-42-60-179-194.ngrok-free.app";
*/

export const VALID_FOR_DURATION = "24 hours";
export const PB_LOCATION = "Brewnanza";
export const PRIVACY_CLAUSE= `
<p>By making payment and utilising this photo booth service, you agree to the below</p>
<p style="margin-top:30px;">(1) You consent to the collection, use and sharing of your photos for marketing purposes. The companies 
(Instantly Singapore Pte Ltd and The Craft Alliance Pte Ltd may use your photos in their marketing materials, 
including but not limited to social media, website, and print advertisements. Your photos will not be shared with 
third parties for marketing purposes.</p>
<p style="margin-top:15px;">(2) The email address you entered is for the purpose of sending you soft copies of your photos and you consent 
to the collection and use of your email address for marketing purposes. Your email address will not be shared with 
any third parties.</p>
<p style="margin-top:15px;">(3) In order to protect our equipment, a CCTV has been installed to monitor usage at the photo booth. You consent 
to having your usage footage recorded for this sole purpose. Your footage will not be shared with any third parties
or used for any other purposes.</p>
<p style="margin-top:15px;">(4) The company is not liable for any injury, damage, or loss that may occur during the photo booth session. Please 
use the photo booth with care and at your own risk.</p>
<p style="margin-top:15px;">(5) You consent to waive all rights to inspect or approve any photographs taken.</p>
<p style="margin-top:30px;">If you do not consent to any of the above clause, please DO NOT proceed to use this photo booth.</p>
`;

export const MODAL_CLOSE_BUTTON = {
  backgroundColor: "#F26522",
  color: "white",
  borderColor: "white",
  buttonText: "Close",
  topSpace: "20px",
  width: "40%"
};

export const START_BUTTON = {
  backgroundColor: "#F26522",
  color: "white",
  borderColor: "white",
  buttonText: "START",
  topSpace: "20px",
  fontSize: "30px",
  width: "70%"
};

export const MAIN_MENU_PAGE = {
  promo_code: {
    image_url:
        "https://instantly.sg/wp-content/uploads/2024/01/instantlysg-pbqr2024-promocode.jpg",
    button: {
      backgroundColor: "#F26522",
      color: "white",
      borderColor: "transparent",
      buttonText: "PROMO CODE REDEMPTION",
      width: "100%",
      topSpace: "0px"
    },
  },
  pay_per_user: {
    image_url:
        "https://instantly.sg/wp-content/uploads/2024/01/instantlysg-pbqr2024-payperuse.jpg",
    button: {
      backgroundColor: "#F26522",
      color: "white",
      borderColor: "transparent",
      buttonText: "PAY PER USE",
      width: "100%",
      topSpace: "0px"
    },
  },
};

export const SUBMIT_BUTTON = {
  backgroundColor: "#F26522",
  color: "white",
  borderColor: "transparent",
  buttonText: "SUBMIT",
  width: "60%"
};

export const FORM_SUBMIT_BUTTON = {
  backgroundColor: "#F26522",
  color: "white",
  borderColor: "transparent",
  buttonText: "SUBMIT",
  width: "60%"
};

export const PROMO_CODE_REDEMPTION_BACK_BUTTON = {
  backgroundColor: "transparent",
  color: "black",
  borderColor: "black",
  buttonText: "BACK",
  width: "60%",
  marginTop: "10px"
};

export const SELECT_BUTTON = {
  backgroundColor: "#F26522",
  color: "white",
  borderColor: "transparent",
  buttonText: "SELECT",
  width: "100%",
};

export const PROCEED_PAYMENT_FORM = {
  backgroundColor: "#F26522",
  color: "white",
  borderColor: "transparent",
  buttonText: "NEXT -- Registration",
  width: "100%",
};

export const BACK_BUTTON = {
  backgroundColor: "transparent",
  color: "black",
  borderColor: "black",
  buttonText: "BACK",
  width: "50%",
  fontSize: "10px",
  marginTop: "10px",
  marginBottom: "50px"
};

export const NOT_FOUND_BACK_HOME_BUTTON = {
  backgroundColor: "transparent",
  color: "black",
  borderColor: "black",
  buttonText: "BACK TO HOME",
  width: "60%"
}

export const BACK_HOME_BUTTON = {
  backgroundColor: "transparent",
  color: "black",
  borderColor: "black",
  buttonText: "BACK TO HOME",
  width: "100%"
};

export const LOGO_URL =
  "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/instantlysg-logo.png";

export const CLIENT_LOGO =
  "https://breezeqr.s3.ap-southeast-1.amazonaws.com/brewnanza/brewnanza-logo.png";

export const OPTIONS = [
  {
    price_id: "price_1OZhhfFSPyGYuqhnz78ULx00",
    title: "2 x Bookmark Printouts",
    description: "You will receive 2 bookmark printouts and all softcopies (GIF and Photos) via email",
    amount: "$10",
    url: "/checkout-first-option",
    image_url:
        "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/10dollars-2strips.jpg",
  },
  {
    price_id: "price_1OZhiUFSPyGYuqhnXv1VaFFe",
    title: "4 x Bookmark Printouts",
    description: "You will receive 4 bookmark printouts and all softcopies (GIF and Photos) via email",
    amount: "$12",
    url: "/checkout-second-option",
    image_url:
        "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/12dollars-4strips.jpg",
  },
  {
    price_id: "price_1OZhitFSPyGYuqhnZOz5k5FE",
    title: "6 x Bookmark Printouts",
    description: "You will receive 6 bookmark printouts and all softcopies (GIF and Photos) via email",
    amount: "$14",
    url: "/checkout-third-option",
    image_url:
        "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/14dollars-6strips.jpg",
  }
];


//Below price options are in Stripe Test mode
//Remember to change in App.js and PaymentMenu.js

export const TEST_OPTIONS = [
  {
    price_id: "price_1Pp54jFSPyGYuqhnudbbSlfw",
    title: "2 x Bookmark Printouts",
    description: "You will receive 2 bookmark printouts and all softcopies (GIF and Photos) via email",
    amount: "$10",
    url: "/checkout-first-option",
    image_url:
        "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/10dollars-2strips.jpg",
  },
  {
    price_id: "price_1Pp54GFSPyGYuqhn3PikeeQx",
    title: "4 x Bookmark Printouts",
    description: "You will receive 4 bookmark printouts and all softcopies (GIF and Photos) via email",
    amount: "$12",
    url: "/checkout-second-option",
    image_url:
        "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/12dollars-4strips.jpg",
  },
  {
    price_id: "price_1Pp53oFSPyGYuqhnqAc4nQDI",
    title: "6 x Bookmark Printouts",
    description: "You will receive 6 bookmark printouts and all softcopies (GIF and Photos) via email",
    amount: "$14",
    url: "/checkout-third-option",
    image_url:
        "https://breezeqr.s3.ap-southeast-1.amazonaws.com/general/14dollars-6strips.jpg",
  }
];
