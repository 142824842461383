import { loadStripe } from "@stripe/stripe-js";
import { BACKEND_URL, STRIPE_API_KEY } from "../config/constants";
import React, { useEffect, useState } from "react";
import {Box, Skeleton, Typography} from "@mui/material";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";

const stripePromise = loadStripe(STRIPE_API_KEY);

const CheckoutFormPage = ({ priceId }) => {
  const mySliceState = useSelector((state) => state);
  console.log('Current State:', mySliceState);

  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Create a Checkout Session as soon as the page loads
  useEffect(() => {
    let apiURL = BACKEND_URL + `/stripe/api/create-checkout-session`;
    //let apiURL = BACKEND_URL + `/stripe/api/create-qr-checkout-session`;

    const createCheckoutSession = async (priceId) => {
      console.log("price id is ", priceId); //why is this logged twice?
      setIsLoading(true);
      try {
        const response = await axios.post(apiURL, priceId, {
          headers: {
            "Content-Type": "text/plain",
          },
        });
        if (response.data.status === "failed") {
          console.log(
            "POST request failed at initial mount:",
            response.data.error
          );
          console.log("Error response is:", response.data);
          console.log("Retrying...");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          console.log("Data received from create checkout session");
          setClientSecret(response.data.clientSecret);
        }
      } catch (error) {
        console.error("Error in try catch POST request. Error is: ", error);
        console.log("Retrying...");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
      setIsLoading(false);
    };
    createCheckoutSession(priceId);
  }, []);

  return (
    <div id="checkout">
      <div style={{ marginBottom: "30px", textAlign: "center" }}>
        <Link to={"/payment-menu"}>
          <Typography fontSize={18} variant="h1" fontWeight={500} my={3}>
            Back to previous screen
          </Typography>
        </Link>
      </div>
      {isLoading && (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1}>
          <span>Loading...</span>
          <Skeleton variant="rounded" width={400} height={30} />
          <Skeleton variant="rounded" width={400} height={30} />
        </Box>
      )}
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default CheckoutFormPage;
