import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {BACKEND_URL, PROMO_CODE_REDEMPTION_BACK_BUTTON, SUBMIT_BUTTON} from "../config/constants";
import axios from "axios";
import {Box, Card, CardActions, CardContent, TextField, Typography,} from "@mui/material";
import ButtonComponent from "./Buttons/ButtonComponent";
import {setData} from "../store";
import {useDispatch} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const PromoLandingPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState("");
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);

    const apiURL = BACKEND_URL + "/instantlysg/api/promoCode";
    const handleClick = (url) => {
        navigate(url);
    };

    const onChangeInput = (event) => {
        const input = event.target.value;
        setInput(input);
        setErrorMessage("");
    };

    const handleButtonClick = (e) => {
        e.preventDefault();

        setLoading(true);
        axios
            .post(apiURL, input, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                console.log("API response:", response.data);
                if (response.data.includes("Valid")) {
                    console.log("Promo code is valid");

                    dispatch(
                        setData({
                            fromPromoCodeRedemption: true,
                        })
                    );

                    navigate("/form");
                } else if (response.data.includes("Invalid")) {
                    console.error("Invalid promo code");
                    setErrorMessage(response.data);
                } else {
                    // Handle other cases if needed
                }
            })
            .catch((error) => {
                console.error("API error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            {
                loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100vh"
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <CircularProgress style={{color: '#F26522'}}/>
                    </Box>
                ) : (
                    <Card
                        sx={{maxWidth: "400px", margin: "auto", padding: "20px 0"}}
                        style={{width: "100%", boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.1)"}}
                    >
                        <CardContent>
                            <Typography gutterBottom fontSize={36} variant="h1" fontWeight={500}>
                                PROMO CODE REDEMPTION
                            </Typography>
                            <Typography variant="body1" my={2} color={"rgba(0, 0, 0, 0.9)"}>
                                Enter your promo code below
                            </Typography>
                        </CardContent>
                        <form id="promo-form" onSubmit={handleButtonClick}>
                            <TextField
                                id="outlined-basic"
                                label="Promo Code:"
                                variant="outlined"
                                helperText={errorMessage}
                                error={errorMessage}
                                value={input}
                                onChange={onChangeInput}
                                required
                                style={{width: '60%'}}
                            />
                            <Box
                                mt={4}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                gap={2}
                            ></Box>
                        </form>
                        <CardActions>
                            <ButtonComponent
                                backgroundColor={SUBMIT_BUTTON.backgroundColor}
                                borderColor={SUBMIT_BUTTON.borderColor}
                                buttonText={SUBMIT_BUTTON.buttonText}
                                color={SUBMIT_BUTTON.color}
                                onClick={() => {
                                }}
                                type="submit"
                                width={SUBMIT_BUTTON.width}
                                formID={"promo-form"}
                            />
                        </CardActions>
                        <CardActions>
                            <ButtonComponent
                                backgroundColor={PROMO_CODE_REDEMPTION_BACK_BUTTON.backgroundColor}
                                borderColor={PROMO_CODE_REDEMPTION_BACK_BUTTON.borderColor}
                                buttonText={PROMO_CODE_REDEMPTION_BACK_BUTTON.buttonText}
                                color={PROMO_CODE_REDEMPTION_BACK_BUTTON.color}
                                width={PROMO_CODE_REDEMPTION_BACK_BUTTON.width}
                                onClick={() => handleClick('/main-menu')}
                            />
                        </CardActions>
                    </Card>
                )
            }
        </div>
    );
};
export default PromoLandingPage;
