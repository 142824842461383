import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import {Link} from "react-router-dom";
import {MAIN_MENU_PAGE} from "../config/constants";
import ButtonComponent from "./Buttons/ButtonComponent";
import BackButton from "./Buttons/BackButton";
import {useSelector} from "react-redux";

const MainMenu = () => {
    const mySliceState = useSelector((state) => state);
    console.log('Current State:', mySliceState);

    const {pay_per_user, promo_code} = MAIN_MENU_PAGE;

    return (
        <>
            <Typography fontSize={30} variant="h1" fontWeight={500} my={3}>
                SELECT AN OPTION BELOW
            </Typography>
            <Grid container justifyContent={"center"} spacing={1}>
                <Grid lg={6} px={1} sx={{maxWidth: "430px"}}>
                    <img
                        src={promo_code.image_url}
                        alt="promo code image"
                        width={200}
                        height={200}
                        style={{width: "100%"}}
                        className="object-cover"
                    />
                    <Link to={"/promo-redemption"}>
                        <ButtonComponent
                            backgroundColor={promo_code.button.backgroundColor}
                            borderColor={promo_code.button.borderColor}
                            buttonText={promo_code.button.buttonText}
                            color={promo_code.button.color}
                            width={promo_code.button.width}
                            topSpace={promo_code.button.topSpace}
                            fontSize={promo_code.button.fontSize}
                            onClick={() => {
                            }}
                        />
                    </Link>
                </Grid>
                <Grid lg={6} px={1} sx={{maxWidth: "430px"}}>
                    <img
                        src={pay_per_user.image_url}
                        alt="pay per use image"
                        width={200}
                        height={200}
                        style={{width: "100%"}}
                        className="object-cover"
                    />
                    <Link to={"/payment-menu"}>
                        <ButtonComponent
                            backgroundColor={pay_per_user.button.backgroundColor}
                            borderColor={pay_per_user.button.borderColor}
                            buttonText={pay_per_user.button.buttonText}
                            color={pay_per_user.button.color}
                            width={pay_per_user.button.width}
                            topSpace={pay_per_user.button.topSpace}
                            fontSize={pay_per_user.button.fontSize}
                            onClick={() => {
                            }}
                        />
                    </Link>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Link to={"/"}>
                    <BackButton/>
                </Link>
            </Box>
        </>
    );
};

export default MainMenu;
