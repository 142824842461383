import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {BACKEND_URL, FORM_SUBMIT_BUTTON} from "../config/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {setData} from "../store";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Fade,
    FormControl,
    FormControlLabel,
    IconButton,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import ButtonComponent from "./Buttons/ButtonComponent";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";

const options = [
    {
        label: "Design 1",
        value: "1",
        imageUrl: "https://breezeqr.s3.ap-southeast-1.amazonaws.com/brewnanza/brewnanza-design1-option.jpg"
    },
    {
        label: "Design 2",
        value: "2",
        imageUrl: "https://breezeqr.s3.ap-southeast-1.amazonaws.com/brewnanza/brewnanza-design2-option.jpg"
    }
    // {
    //     label: "Design 3",
    //     value: "3",
    //     imageUrl: "https://instantly.sg/wp-content/uploads/2024/01/option-1.jpg"
    // },
    // {
    //     label: "Design 4",
    //     value: "4",
    //     imageUrl: "https://instantly.sg/wp-content/uploads/2024/01/option-1.jpg"
    // }
];

const DataCaptureForm = ({headerText, description}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [template, setTemplate] = useState(options[0].value);
    const [enlargedImage, setEnlargedImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const paymentStatus = useSelector((state) => state.paymentStatus);
    const paymentCustomerEmail = useSelector(
        (state) => state.paymentCustomerEmail
    );
    const paymentAmount = useSelector((state) => state.paymentAmount);
    const paymentId = useSelector((state) => state.paymentId);
    const fromPromoCodeRedemption = useSelector((state) => state.fromPromoCodeRedemption);

    const apiURL = BACKEND_URL + `/instantlysg/api/form-submit`;

    const openImageModal = (imageUrl) => {
        setEnlargedImage(imageUrl);
    };

    const closeImageModal = () => {
        setEnlargedImage(null);
    };

    useEffect(() => {
        if (paymentStatus === "" || paymentStatus === null) {
            if (!fromPromoCodeRedemption) {
                console.log("status is null or empty. Directing to / ")
                navigate("/");
                }
            }
    }, []);

    const handleOptionChange = (event) => {
        setTemplate(event.target.value);
    };

    const handleClick = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            // Create an object with the form data
            const dataToBackend = {
                formFieldData: {
                    name: name,
                    email: email,
                    templateChosen: template,
                },
                stripePaymentData: {
                    paymentStatus: paymentStatus,
                    paymentCustomerEmail: paymentCustomerEmail,
                    paymentId: paymentId,
                    paymentAmount: paymentAmount,
                },
            };

            const response = await axios.post(apiURL, dataToBackend, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.data !== null) {
                console.log("Server generated QR ticket. URL is ", response.data);
                dispatch(
                    setData({
                        breezeQrUrl: response.data,
                    })
                );
                navigate("/thank-you");
            } else {
                console.log("Something wrong. Server did not generate our QR url");
            }
        } catch (error) {
            // Handle error
            console.error("Error submitting data:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress style={{color: '#F26522'}}/>
                </Box>
            ) : (
                <Card
                    style={{
                        maxWidth: "430px",
                        margin: "auto",
                        boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <CardContent>
                        <Typography fontSize={36} variant="h1" fontWeight={500} mt={3} mb={1}>
                            {headerText}
                        </Typography>
                        <Typography mb={4} variant="body1">
                            {description}
                        </Typography>

                        <form id="submit-form" onSubmit={handleClick}>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                gap={2}
                            >
                                <TextField
                                    id="name"
                                    label="Name:"
                                    variant="outlined"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <TextField
                                    id="email"
                                    label="Email Address:"
                                    variant="outlined"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <Typography mb={4} variant="body3" style={{textAlign: 'left', fontStyle: 'italic'}}>
                                    ** Please ensure the email address you entered above is correct. We will email all
                                    softcopies to this email address.
                                </Typography>

                                <Typography mb={2} variant="body3" style={{textAlign: 'left'}}>
                                    Please choose a design for your printout<br/>Click on the each image to see an
                                    enlarged view.
                                </Typography>
                            </Box>

                            <Grid container spacing={2} rowGap={2}>
                                {options.map(({label, value, imageUrl}) => (
                                    <Grid key={value}
                                          item xs={6}
                                          display={"flex"}
                                          flexDirection={"column"}
                                          alignItems={"center"}
                                          justifyContent={"space-between"}>
                                        <img
                                            src={imageUrl}
                                            alt={`Option ${value}`}
                                            style={{maxWidth: "100%", cursor: "pointer"}}
                                            onClick={() => openImageModal(imageUrl)}
                                        />
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue={options[0].value}
                                                name="radio-buttons-group"
                                            >
                                                <FormControlLabel
                                                    value={value}
                                                    checked={template === value}
                                                    onChange={handleOptionChange}
                                                    control={<Radio/>}
                                                    label={label}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                ))}

                                {/* Image Modal */}
                                <Modal
                                    open={enlargedImage !== null}
                                    onClose={closeImageModal}
                                    aria-labelledby="image-modal"
                                    aria-describedby="image-content"
                                    disableAutoFocus
                                    closeAfterTransition
                                >
                                    <Fade in={enlargedImage !== null}>
                                        <div style={{textAlign: "center"}}>
                                            <IconButton
                                                edge="end"
                                                color="inherit"
                                                onClick={closeImageModal}
                                                aria-label="close"
                                                style={{position: "absolute", top: 10, right: 10}}
                                            >
                                                <CloseIcon/>
                                            </IconButton>
                                            <img
                                                src={enlargedImage}
                                                alt="Enlarged Image"
                                                style={{maxWidth: "100%"}}
                                            />
                                        </div>
                                    </Fade>
                                </Modal>
                            </Grid>
                        </form>
                    </CardContent>
                    <CardActions style={{marginBottom: '5em'}}>
                        <ButtonComponent
                            backgroundColor={FORM_SUBMIT_BUTTON.backgroundColor}
                            borderColor={FORM_SUBMIT_BUTTON.borderColor}
                            buttonText={FORM_SUBMIT_BUTTON.buttonText}
                            color={FORM_SUBMIT_BUTTON.color}
                            width={FORM_SUBMIT_BUTTON.width}
                            onClick={() => {
                            }}
                            type="submit"
                            formID={"submit-form"}

                        />
                    </CardActions>
                </Card>
            )}
        </div>
    );
};

export default DataCaptureForm;
