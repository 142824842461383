import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import ButtonComponent from "./Buttons/ButtonComponent";
import {BACK_HOME_BUTTON, PB_LOCATION, VALID_FOR_DURATION} from "../config/constants";

const ThankYouPage = () => {
    const navigate = useNavigate();
    const breezeQr = useSelector((state) => state.breezeQrUrl);
    const paymentStatus = useSelector((state) => state.paymentStatus);
    const fromPromoCodeRedemption = useSelector((state) => state.fromPromoCodeRedemption);

    useEffect(() => {
        if (paymentStatus === "" || paymentStatus === null) {
            if (!fromPromoCodeRedemption) {
                console.log("status is null or empty. Directing to / ");
                navigate("/");
            }
        }
    }, []);

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography fontSize={48} variant="h1" fontWeight={500} my={3}>
                THANK YOU
            </Typography>
            <Typography gutterBottom variant="body1" mb={4}>
                Below is your QR ticket to activate our photo booth.<br/>Please keep it safely.<br/>
                <span style={{fontWeight: 700, textDecoration: 'underline'}}>
                We advise you to take a
                screenshot of it now.
                </span>
            </Typography>
                <Typography gutterBottom variant="body1" mb={4}>
                To use it, touch the START button on our photo booth screen to scan your QR ticket. 
                </Typography>
            <img
                src={breezeQr}
                alt="QR Ticket"
                width={250}
                height={250}
                style={{objectFit: "contain", marginBottom: "1em"}}
            />
            <Typography gutterBottom variant="body1">
                You can also retrieve this QR ticket from you email. We've emailed it to the email address
                you entered in the previous screen. Please check your Junk/Spam box if you don't see it in your inbox.
            </Typography>
            <Typography gutterBottom variant="body1" fontWeight={700} mt={3}>
                IMPORTANT NOTE:
            </Typography>
            <Typography gutterBottom variant="body1" mb={3}>
                This QR ticket is{" "}
                <span style={{fontWeight: 700, textDecoration: 'underline'}}>
                    {"valid for " + VALID_FOR_DURATION}
                </span>{" "}
                and can only be used 
                <span style={{fontWeight: 700, textDecoration: 'underline'}}>
                    {" ONCE"} 
                </span> at our photo booth at
                {" "}
                <span style={{fontWeight: 700, textDecoration: 'underline'}}>
                    {PB_LOCATION}
                </span>{"."}
            </Typography>

            <Typography gutterBottom variant="body1" mb={3}>
                If you face any issues, please click{" "}
                <Link
                    to="https://wa.me/6592313536"
                    target="_blank"
                >
                    HERE
                </Link>{" "}
                to WhatsApp us
            </Typography>

            <Link to={"/"}>
                <ButtonComponent
                    backgroundColor={BACK_HOME_BUTTON.backgroundColor}
                    borderColor={BACK_HOME_BUTTON.borderColor}
                    buttonText={BACK_HOME_BUTTON.buttonText}
                    color={BACK_HOME_BUTTON.color}
                    width={BACK_HOME_BUTTON.width}
                    bottomSpace={100}
                    onClick={() => {
                    }}
                />
            </Link>
        </Box>
    );
};

export default ThankYouPage;
