import {Fade, Modal, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {MODAL_CLOSE_BUTTON, PB_LOCATION, PRIVACY_CLAUSE, START_BUTTON} from "../config/constants";
import ButtonComponent from "./Buttons/ButtonComponent";
import {useDispatch, useSelector} from 'react-redux';
import {resetState} from "../store";
import {CLIENT_LOGO} from "../config/constants";

const LandingPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log("Component initial mount, resetting state");
        dispatch(resetState());
    }, [dispatch]);

    const mySliceState = useSelector((state) => state);
    console.log('Current State:', mySliceState);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (url) => {
        navigate(url);
    };

// <Typography fontWeight={500} fontSize={48} variant="h1">
// WELCOME
// </Typography>
// <Typography fontSize={30} variant="body1" mt={2} mb={5}>
// Photo Booth at {PB_LOCATION}
// </Typography>

    return (
        <div>
            <img
                src={CLIENT_LOGO}
                alt="brewnanza image"
                style={{width: "60%"}}
                className="object-cover"
            />

            <Typography fontWeight={500} fontSize={30} variant="body1" mt={5} mb={5}>
            PHOTO BOOTH QR TICKET PURCHASE
            </Typography>

            <Typography fontSize={18} variant="body1" mt={7} mb={2}>
                By pressing the START button below you approve to the terms and conditions of using this photo booth
            </Typography>

            <Typography fontSize={18} variant="body1" mb={10}>
                Click <span onClick={handleOpen} style={{ cursor: "pointer", textDecoration: "underline", fontWeight: "700" }}>HERE</span> to view our Terms and Conditions
            </Typography>


            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="terms-and-conditions-modal"
                aria-describedby="terms-and-conditions-content"
                disableAutoFocus
                closeAfterTransition
            >
                <Fade in={open}>
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "20px",
                        width: "80%",
                        maxWidth: "500px",
                        maxHeight: "80vh", // Set a fixed height with a maximum of 80% of the viewport height
                        overflowY: "auto", // Enable vertical scrolling if the content overflows
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <Typography variant="body1" dangerouslySetInnerHTML={{__html: PRIVACY_CLAUSE}}/>

                        <ButtonComponent
                            backgroundColor={MODAL_CLOSE_BUTTON.backgroundColor}
                            borderColor={MODAL_CLOSE_BUTTON.borderColor}
                            buttonText={MODAL_CLOSE_BUTTON.buttonText}
                            color={MODAL_CLOSE_BUTTON.color}
                            topSpace={MODAL_CLOSE_BUTTON.topSpace}
                            width={MODAL_CLOSE_BUTTON.width}
                            onClick={handleClose}
                        />
                    </div>
                </Fade>
            </Modal>

            <ButtonComponent
                backgroundColor={START_BUTTON.backgroundColor}
                borderColor={START_BUTTON.borderColor}
                buttonText={START_BUTTON.buttonText}
                color={START_BUTTON.color}
                topSpace={START_BUTTON.topSpace}
                fontSize={START_BUTTON.fontSize}
                width={START_BUTTON.width}
                onClick={() => handleClick("/payment-menu")} //if want to go to menu where we can do redemption, change to /main-menu
            />
        </div>
    );
};

export default LandingPage;
