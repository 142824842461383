import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ButtonComponent from "./Buttons/ButtonComponent";
import { SELECT_BUTTON } from "../config/constants";

const ProductButton = ({ headerText, description, amount, url, imageURL }) => {
  return (
    <Box
      sx={{
        width: "80%", // Make the entire component smaller (adjust percentage as needed)
        margin: "0 auto", // Center the component horizontally
        padding: "5px", // Add padding to create space around the card
      }}
    >
      <Card
        sx={{
          width: "100%", // Card takes full width of the Box
          height: "100%",
          boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Ensures content is spaced evenly
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: "auto", width: "100%", objectFit: "cover" }}
          image={imageURL}
          title={headerText}
        />
        <CardContent>
          <Typography gutterBottom fontSize={20} variant="h1" fontWeight={550} mb={2}>
            {amount} -- {headerText}
          </Typography>
          <Typography variant="body2">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Link style={{ width: "100%" }} to={url}>
            <ButtonComponent
              backgroundColor={SELECT_BUTTON.backgroundColor}
              borderColor={SELECT_BUTTON.borderColor}
              buttonText={SELECT_BUTTON.buttonText}
              color={SELECT_BUTTON.color}
              width={SELECT_BUTTON.width}
              onClick={() => {}}
            />
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ProductButton;