import React from 'react';
import {Typography} from "@mui/material";
import {NOT_FOUND_BACK_HOME_BUTTON} from "../config/constants";
import ButtonComponent from "./Buttons/ButtonComponent";
import {useNavigate} from "react-router-dom";

const NotFoundPage = () => {
    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    };

    return (
        <div>
            <Typography fontWeight={500} fontSize={32} variant="h1">
                Page Not Found
            </Typography>
            <Typography fontSize={18} variant="body1" mt={2} mb={5}>
                Sorry, the page you are looking for does not exist.
            </Typography>

            <ButtonComponent
                backgroundColor={NOT_FOUND_BACK_HOME_BUTTON.backgroundColor}
                color={NOT_FOUND_BACK_HOME_BUTTON.color}
                borderColor={NOT_FOUND_BACK_HOME_BUTTON.borderColor}
                buttonText={NOT_FOUND_BACK_HOME_BUTTON.buttonText}
                width={NOT_FOUND_BACK_HOME_BUTTON.width}
                onClick={() => handleClick("/")}
            />
        </div>
    );
};

export default NotFoundPage;