import {Button} from "@mui/material";
import React from "react";

const ButtonComponent = ({
                             backgroundColor,
                             borderColor,
                             buttonText,
                             color,
                             topSpace,
                             bottomSpace,
                             onClick,
                             fontSize,
                             width,
                             type = "button",
                             formID
                         }) => {
    return (
        <Button
            form={formID}
            type={type}
            variant="outlined"
            size="large"
            style={{
                backgroundColor: backgroundColor,
                color: color,
                borderColor: borderColor,
                width: width,
                fontSize: fontSize,
                margin: 'auto',
                marginTop: topSpace,
                marginBottom: bottomSpace
            }}
            onClick={onClick}
        >
            {buttonText}
        </Button>
    );
};

export default ButtonComponent;
