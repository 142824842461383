import "./App.css";
import {Route, Routes, useLocation} from "react-router-dom";
import MainMenu from "./components/MainMenu";
import CheckoutFormPage from "./components/CheckoutFormPage";
import {OPTIONS, TEST_OPTIONS} from "./config/constants";
import ReturnPage from "./components/ReturnPage";
import React, { useEffect } from "react";
import PromoLandingPage from "./components/PromoLandingPage";
import PaymentMenu from "./components/PaymentMenu";
import DataCaptureForm from "./components/DataCaptureForm";
import DataCaptureFormTest from "./components/DataCaptureFormTest";
import LandingPage from "./components/LandingPage";
import ThankYouPage from "./components/ThankYouPage";
import ThankYouPageTest from "./components/ThankYouPageTest";
import {Container} from "@mui/material";
import Navbar from "./components/Navbar";
import NotFoundPage from "./components/NotFoundPage";

function App() {
    useEffect(() => {
        document.title = "Brewnanza";  // Set your global title here
    }, []);
    
    const {pathname} = useLocation();
    return (
        <Container maxWidth="md">
            <Navbar isLarge={pathname === "/"}/>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/main-menu" element={<MainMenu/>}/>
                    <Route path="/promo-redemption" element={<PromoLandingPage/>}/>
                    <Route path="/payment-menu" element={<PaymentMenu/>}/>

                    {OPTIONS.map(({price_id, url}) => (
                        <Route
                            path={url}
                            element={<CheckoutFormPage priceId={price_id}/>}
                        />
                    ))}
                    <Route path="/return" element={<ReturnPage/>}/>

                    <Route
                        path="/form"
                        element={
                            <DataCaptureForm
                                headerText="REGISTRATION"
                                description="Enter your details below"
                            />
                        }
                    />
                    <Route path="/thank-you" element={<ThankYouPage/>}/>

                    <Route
                    path="/testform"
                    element={
                        <DataCaptureFormTest
                            headerText="TEST"
                            description="Enter your details below"
                        />
                    }
                     />
                     <Route path="/test-thank-you" element={<ThankYouPageTest/>}/>

                    {/* Wildcard route for 404 page */}
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </div>
        </Container>
    );
}

export default App;
